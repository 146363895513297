import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Text, Box, Link, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"quarkly-title": "CaseCard",
	"inner-width": "100%",
	"margin": "0px 0 0px 0",
	"padding": "0px 0px 0px 0px"
};
const overrides = {
	"image": {
		"kind": "Image",
		"props": {
			"width": "100%",
			"src": "https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-webask-builder-adding-control-ru-2.png?v=2022-06-20T09:00:22.631Z",
			"display": "none"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "52px 36px 52px 36px",
			"width": "100%",
			"display": "flex",
			"flex-wrap": "wrap",
			"align-content": "flex-start",
			"sm-padding": "32px 24px 52px 24px",
			"flex-direction": "column",
			"height": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--headline1",
			"height": "auto",
			"md-font": "--headline2",
			"sm-margin": "0px 0px 24px 0px",
			"color": "white",
			"sm-font": "--headline3",
			"width": "100%",
			"children": "WebAsk"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "0px 36px 0px 0px",
			"lg-width": "100%",
			"sm-padding": "0px 0px 0px 0px",
			"width": "100%",
			"margin": "0px 0px 40px 0px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"font": "--lead28",
			"max-width": "760px",
			"sm-font": "--lead21",
			"sm-margin": "0px 0px 12px 0px",
			"color": "white",
			"children": "Онлайн конструктор анкет и опросов. C его помощью вы сможете создавать опросы, анкеты, собирать обратную связь, создавать регистрационные формы или даже проводить голосования"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"max-width": "760px",
			"sm-margin": "0px 0px 12px 0px",
			"color": "white",
			"children": "UX / UI дизайн, продуктовый дизайн, менеджмент"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-end",
			"lg-width": "100%",
			"lg-flex-direction": "row",
			"lg-justify-content": "space-between",
			"lg-align-items": "center",
			"lg-margin": "24px 0px 0px 0px",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"sm-margin": "12px 0px 0px 0px",
			"justify-content": "space-between",
			"md-flex-direction": "column",
			"md-justify-content": "flex-start",
			"md-align-items": "flex-start",
			"md-height": "auto",
			"md-min-height": "auto",
			"width": "100%",
			"flex-direction": "row",
			"margin": "auto 0px 0px 0px"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"font": "--lead28",
			"lg-margin": "0px 0px 0px 0px",
			"sm-font": "--lead21",
			"border-width": "0 0 1px 0",
			"border-style": "solid",
			"href": "https://webask.io/",
			"target": "_blank",
			"text-decoration-line": "initial",
			"color": "white",
			"border-color": "white",
			"md-margin": "0px 0px 26px 0px",
			"margin": "0px 0px 0px 0px",
			"children": "webask.io",
			"hover-transform": "translateY(-5px)",
			"transform": "translateY(0px)",
			"transition": "transform 0.1s ease-in-out 0s",
			"hover-transition": "transform 0.1s ease-in-out 0s"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"font": "--lead",
			"padding": "12px 36px 13px 36px",
			"border-radius": "36px",
			"background": "--color-darkL2",
			"sm-margin": "24px 0px 0px 0px",
			"children": "Посмотреть кейс →",
			"href": "/portfolio/webask",
			"type": "link",
			"text-decoration-line": "initial",
			"transform": "translateY(0px)",
			"hover-transform": "translateY(-5px)",
			"hover-transition": "transform 0.1s ease-in-out 0s",
			"transition": "transform 0.1s ease-in-out 0s"
		}
	},
	"SectionContent": {
		"props": {
			"background": "#3382F6",
			"xl-max-width": "100%"
		}
	},
	"text3": {
		"props": {
			"color": "white",
			"border-color": "white",
			"children": "webask.io"
		}
	}
};

const CaseCard = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			align-items="center"
			justify-content="flex-start"
			align-content="flex-start"
			flex-wrap="wrap"
			flex-direction="column"
			background="#63FAE2"
		/>
		<Image {...override("image")} />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</Box>
			<Box {...override("box2")}>
				<Link {...override("link1")} />
				<Button {...override("button")}></Button>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(CaseCard, { ...Section,
	defaultProps,
	overrides
});
export default CaseCard;