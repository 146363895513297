import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Hr, Icon, Text, LinkBox, Section } from "@quarkly/widgets";
import QuarklycommunityKitMenu from "./QuarklycommunityKitMenu";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
const defaultProps = {
	"quarkly-title": "PrevNextButtons",
	"inner-width": "100%",
	"margin": "100px 0 0 0",
	"sm-margin": "100px 0 0 0"
};
const overrides = {
	"hr": {
		"kind": "Hr",
		"props": {
			"min-height": "10px",
			"min-width": "100%",
			"margin": "0px 0px 0px 0px",
			"grid-column": "1/3"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"grid-column": "1/2",
			"width": "100%",
			"padding": "24px 24px 24px 24px",
			"flex-direction": "row",
			"justify-content": "flex-start",
			"href": "/portfolio/stepform",
			"color": "--darkL2",
			"hover-color": "--primary",
			"quarkly-title": "back",
			"sm-padding": "24px 0px 24px 0px",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdArrowBack,
			"size": "52px",
			"color": "inherit",
			"margin": "0px 8px 0px 8px",
			"font": "40px sans-serif",
			"sm-font": "26px sans-serif",
			"sm-margin": "0px 8px 8px 8px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 8px 0px 8px",
			"color": "inherit",
			"font": "--lead21",
			"sm-font": "--lead21",
			"children": "StepFORM"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"grid-column": "2/3",
			"width": "100%",
			"padding": "24px 24px 24px 24px",
			"flex-direction": "row",
			"justify-content": "flex-end",
			"href": "/portfolio/mini-projects",
			"color": "--darkL2",
			"hover-color": "--primary",
			"quarkly-title": "next",
			"sm-flex-direction": "column-reverse",
			"sm-align-items": "flex-end",
			"sm-justify-content": "flex-start",
			"sm-padding": "24px 0px 24px 0px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 8px 0px 8px",
			"color": "inherit",
			"font": "--lead21",
			"sm-text-align": "right",
			"sm-font": "--lead21",
			"md-text-align": "right",
			"children": "Мини-проекты"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdArrowForward,
			"size": "52px",
			"color": "inherit",
			"margin": "0px 8px 0px 8px",
			"font": "40px sans-serif",
			"sm-font": "26px sans-serif",
			"sm-margin": "0px 8px 8px 8px"
		}
	},
	"quarklycommunityKitMenu": {
		"kind": "QuarklycommunityKitMenu",
		"props": {
			"height": "auto",
			"rootId": "6253e41a56d737002476a9fd",
			"font": "--lead21",
			"width": "auto",
			"text-align": "center",
			"display": "flex",
			"grid-column": "1/3",
			"flex-direction": "row-reverse",
			"sm-flex-direction": "column"
		}
	},
	"quarklycommunityKitMenuOverride": {
		"kind": "Override",
		"props": {
			"slot": "link-webask",
			"children": "WebAsk"
		}
	},
	"quarklycommunityKitMenuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link-stepform",
			"children": "stepFORM"
		}
	},
	"quarklycommunityKitMenuOverride2": {
		"kind": "Override",
		"props": {
			"slot": "link-ukitalt",
			"children": "uKit Alt"
		}
	},
	"quarklycommunityKitMenuOverride3": {
		"kind": "Override",
		"props": {
			"slot": "link-ukit",
			"children": "uKit"
		}
	},
	"quarklycommunityKitMenuOverride4": {
		"kind": "Override",
		"props": {
			"slot": "link-mini-projects",
			"children": "Мини-проекты"
		}
	},
	"quarklycommunityKitMenuOverride5": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"text-decoration-line": "initial",
			"color": "--grey",
			"hover-color": "--primary",
			"sm-font": "--lead21",
			"md-font": "--lead21"
		}
	},
	"quarklycommunityKitMenuOverride6": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"hover-color": "--dark",
			"color": "--dark",
			"cursor": "default"
		}
	},
	"quarklycommunityKitMenuOverride7": {
		"kind": "Override",
		"props": {
			"slot": "item-quarkly",
			"display": "none"
		}
	}
};

const PrevNextButtons = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			max-width="1920px"
			width="100%"
			display="grid"
			grid-template-columns="repeat(2, 1fr)"
			grid-gap="0px 24px"
			md-grid-template-columns="1fr 1fr"
			justify-items="center"
		/>
		<Hr {...override("hr")} />
		<LinkBox {...override("linkBox")}>
			<Icon {...override("icon")} />
			<Text {...override("text")} />
		</LinkBox>
		<LinkBox {...override("linkBox1")}>
			<Text {...override("text1")} />
			<Icon {...override("icon1")} />
		</LinkBox>
		<QuarklycommunityKitMenu {...override("quarklycommunityKitMenu")}>
			<Override {...override("quarklycommunityKitMenuOverride")} />
			<Override {...override("quarklycommunityKitMenuOverride1")} />
			<Override {...override("quarklycommunityKitMenuOverride2")} />
			<Override {...override("quarklycommunityKitMenuOverride3")} />
			<Override {...override("quarklycommunityKitMenuOverride4")} />
			<Override {...override("quarklycommunityKitMenuOverride5")} />
			<Override {...override("quarklycommunityKitMenuOverride6")} />
			<Override {...override("quarklycommunityKitMenuOverride7")} />
		</QuarklycommunityKitMenu>
		{children}
	</Section>;
};

Object.assign(PrevNextButtons, { ...Section,
	defaultProps,
	overrides
});
export default PrevNextButtons;