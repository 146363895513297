import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text } from "@quarkly/widgets";
const defaultProps = {
	"text-transform": "uppercase",
	"letter-spacing": "1px",
	"font": "--base",
	"text-align": "left",
	"margin": "24px 24px 16px 0px",
	"children": "ЗАДАЧА",
	"color": "--grey",
	"grid-column": "1/2",
	"lg-grid-column": "1/4",
	"sm-margin": "0px 24px 16px 0px"
};
const overrides = {};

const BaseUppercase = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Text {...rest}>
		{children}
	</Text>;
};

Object.assign(BaseUppercase, { ...Text,
	defaultProps,
	overrides
});
export default BaseUppercase;