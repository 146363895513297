import React from 'react';
import atomize from "@quarkly/atomize";
import { WindupChildren, Pause } from "windups";

const TypeAnimation = props => <div {...props}>
	    
	<WindupChildren>
		      {" "}      
		<Pause ms={1500} />
		      {"Делаю вдумчивый "}      
		<Pause ms={800} />
		      {"выразительный "}      
		<Pause ms={800} />
		      {"и\u00A0работающий дизайн."}      
		<Pause ms={800} />
		      
		<br />
		      {" Мой фокус — это SaaS-проекты."}    
	</WindupChildren>
</div>;

export default atomize(TypeAnimation)({
	name: "TypeAnimation",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "TypeAnimation — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});